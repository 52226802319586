.container {
  display: flex;
  flex-direction: column;
  padding: 40px;
  gap: 20px;
  align-items: stretch;
  justify-content: flex-start;
  max-width: 30%;
  transition: box-shadow 0.6s ease-out;
}

.container:hover {
  box-shadow: 1px 8px 20px grey;
  transition: box-shadow 0.6s ease-in;
}

.icon {
  width: 100px;
  height: 100px;
}

.featureTitle {
  font-size: 28px;
  font-weight: 500;
  letter-spacing: -0.56px;
  color: var(--black-bg);
}

.featureText {
  font-size: 15px;
  font-weight: 500;
  line-height: 1.6;
  color: var(--text-gray);
}

.actionBtn {
  width: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 14px 20px;
  border-radius: 5px;
  background-color: #f2f2f2;
  box-shadow: 0 0 2px 0 rgba(79, 79, 79, 0.2);
  font-weight: 600;
  font-size: 16px;
  color: #313131;
  white-space: wrap;
  text-align: center;
}

.actionBtn:hover {
  box-shadow: 0 2px 5px 0 rgba(79, 79, 79, 0.2);
}

.actionHidden {
  visibility: hidden;
}

@media (max-width: 1024px) {
  .container {
    min-width: 100%;
    text-align: center;
    justify-content: center;
    align-items: center;
  }

  .featureTitle {
    font-size: 22px;
  }

  .featureText {
    font-size: 14px;
  }
}
