.container {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding: 40px 20px;
    border-radius: 8px;
    background-color: var(--black-bg-light);
    color: var(--white);
    min-width: 25%;
    transition: box-shadow .6s ease-out;
}

.container:hover {
    box-shadow: 1px 8px 20px rgba(0,0,0,0.8);
    transition: box-shadow .6s ease-in;
}

.icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: auto;
}

.icon img {
    position: relative;
    width: 100%;
    height: auto;
    object-fit: cover;
}

.title {
    font-size: 20px;
    font-weight: 600;
}

.text {
    font-size: 14px;
    line-height: 1.5;
    letter-spacing: 0.28px;
}

@media (max-width: 1024px) {
    .container {
        min-width: 100%;
    }
}